.mlc-carousel {
  position: relative;
  width: 80vw;
  height: 45vw;
  max-width: 100%;
  max-height: 56.25vh;
  overflow: hidden;
  margin: 0 auto;
}

.mlc-carousel-slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.mlc-carousel-slide.active {
  opacity: 1;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .mlc-carousel {
    width: 90vw;
    height: 50vw;
  }
}

@media (max-width: 480px) {
  .mlc-carousel {
    width: 100vw;
    height: 56.25vw;
  }
}
