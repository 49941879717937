@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
:root {
  /* blue theme */
  /* --primary-color: #6091ba;
  --primary-color-lighter: #6ea2cc;
  --secondary-color: #2f506c;
  --secondary-color-alt: #2f506c;
  --secondary-color-darker: #27435a;
  --secondary-color-darker-box-shadow: #27435a87;
  --social-navbar-background-color: #2f506c; */

  /* red theme */
  --primary-color: #d50000;
  --primary-color-lighter: #fa0000;
  --secondary-color: #b00000;
  --secondary-color-darker: #8b0000;
  --secondary-color-darker-box-shadow: #470e0d87;
  --secondary-color-alt: #ffaf00;
  --social-navbar-background-color: #660000;

  --section-one-bg-color: #f3f6f9;
  --section-two-bg-color: #f3f6f9;

  --footer-text-color: #ffffff;
  --footer-background-color: #3f3f3f;
  --footer-lower-background-color: #252525;
  --divider-color: #333;
  --background-dull-color: #afafaf;
  --flip-card-front-font-color: #000000;
  --flip-card-back-border-color: #cfb681;

  /* Global Fonts */
  --font-family-primary: "Poppins", Helvetica, Arial, sans-serif;
  --text-color: #333;
  --text-color-light: #ffffff;
  --background-color: #f0f0f0;
  --error-msg-color: #ff0000;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: var(--font-family-primary);
    margin: 20px 0;
    text-align: center;
  }

  h1 {
    font-size: 2.5em;
    font-weight: bold;
  }

  h2 {
    font-size: 2em;
    font-weight: bold;
  }

  h3 {
    font-size: 1.75em;
  }

  h4 {
    font-size: 1.5em;
  }

  h5 {
    font-size: 1.25em;
  }

  h6 {
    font-size: 1em;
  }
  p {
    padding: 0px;
    font-family: var(--font-family-primary);
    line-height: 1.6;
    margin: 15px 0;
    text-align: justify;
  }
}
body {
  margin: 0;
  padding: 0;
  font-family: var(--font-family-primary);
  background-color: var(--background-color);
  color: var(--text-color);
}
.no-margin {
  margin: 0;
}
.tabbed-paragraph {
  text-indent: 60px;
}

.drop-case {
  font-family: var(--font-family-primary);
  font-size: 16px;
  line-height: 1.6;
}

.drop-case::first-letter {
  float: left;
  font-size: 3em;
  font-weight: bold;
  margin-right: 5px;
  line-height: 1;
  color: var(--text-color);
}

p.lead {
  font-size: 1.2em;
  font-weight: bold;
  margin-top: 20px;
}

p.small {
  font-size: 0.9em;
  color: #666;
}
/* General table styling */
table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 1rem;
  font-family: var(--font-family-primary);
}

/* Table Header styling */
thead {
  background-color: var(--primary-color);
  color: var(--text-color-light);
}

th {
  padding: 12px;
  text-align: center;
  font-weight: bold;
  border-bottom: 2px solid var(--primary-color);
}

/* Table Body styling */
tbody tr {
  border-bottom: 1px solid var(--primary-color-lighter);
}

td {
  padding: 10px;
  text-align: left;
}

/* Zebra stripe effect */
tbody tr:nth-child(even) {
  background-color: var(--section-two-bg-color);
}

tbody tr:nth-child(odd) {
  background-color: var(--section-one-bg-color);
}

/* Hover effect on table rows */
tbody tr:hover {
  background-color: var(--background-dull-color);
  /* color: var(--background-dull-color); */
}

/* Table Footer styling */
tfoot {
  background-color: var(--primary-color);
  color: var(--text-color-light);
}

tfoot th,
tfoot td {
  padding: 10px;
  text-align: right;
  border-top: 2px solid var(--primary-color);
}
/* margin below each li */
li {
  margin-bottom: 1rem;
}
.bordered-div {
  border: 2px solid var(--primary-color-lighter);
  padding-right: 1rem;
}

.navbar {
  width: 60vw;
  height: 40px;
  margin: auto;
}

.navbar-items {
  display: flex;
  padding: 0;
  margin: 0;
  height: 100%;
  list-style-type: none;
}
.navbar-items li:hover {
  background-color: var(--secondary-color);
}
.navbar-items li.visited {
  background-color: var(--secondary-color);
}
.navbar-items-li {
  position: relative;
  text-decoration: none;
  height: 100%;
  line-height: 40px;
  padding: 0 1.5rem;
  font-size: 1rem;
  color: var(--text-color-light);
}
.navbar-items-link {
  text-decoration: none;
  color: var(--text-color-light);
}
.navbar-items li:hover {
  background-color: var(--secondary-color);
}
.navbar-items li.visited {
  background-color: var(--secondary-color);
}
.submenu-li {
  margin: 0px;
  font-size: 1rem;
}
.submenu-link {
  text-decoration: none;
  display: block;
  width: 100%;
  padding: 8px 20px;
  text-transform: none;
  font-size: 1rem;
  background-color: var(--secondary-color);
  color: var(--text-color-light);
}
.submenu-link:hover {
  background-color: var(--secondary-color-darker);
}
.activeNavbarItem {
  background-color: var(--secondary-color);
}
.social-navbar-bg-color {
  width: 100vw;
  background-color: var(--social-navbar-background-color);
}
.socialNavbarContainer {
  display: flex;
  align-items: center;
  width: 60vw;
  margin: auto;
  justify-content: space-between;
  height: 50px;
}
.social-navbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  gap: 3px;
}

.social-link {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  background-color: var(--secondary-color-darker);
  border-radius: 50%;
  color: rgba(255, 255, 255, 0.4);
  font-size: 18px;
  transition: background-color 0.3s ease;
}

.social-link:hover {
  background-color: var(--primary-color);
}
.excerptContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  padding-bottom: 1rem;
}

.excerptText {
  margin: 0;
  /* Other styles for text */
}
/* pdf list */
.pdfListContainer {
  width: 100%;
  height: 400px;
  overflow-y: auto;
  /* border: 1px solid ; */
  padding: 5px;
  box-sizing: border-box;
  /* Hide scrollbar */
  /* IE and Edge */
  -ms-overflow-style: none;
  /* Firefox */
  scrollbar-width: none;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.pdfListContainer::-webkit-scrollbar {
  display: none;
}
.pdfList {
  list-style-type: none;
  margin: 0;
  padding: 0 20px 0 20px;

  /* padding-left: 20px; */
}

.pdfListItem {
  margin: 20px 0 20px 0;
  position: relative;
  padding-left: 20px;
  color: var(--text-color);
}
.pdfListItem::before {
  content: "➤";
  position: absolute;
  left: 0;
  color: var(--primary-color);
  font-size: 16px;
}
.pdfListItem a {
  text-decoration: none;
  color: var(--text-color);
}
.pdfListItem:hover {
  border-top: 1px dashed var(--primary-color);
  border-bottom: 1px dashed var(--primary-color);
}
.pdfListItem a:hover {
  text-decoration: none;
  color: var(--secondary-color-darker);
}

/* ImageGallery.css */
.image-grid-container {
  position: relative;
  width: 100%;
  max-width: 600px;
  margin: 0px auto;
  min-height: 400px;
  margin-bottom: 20px;
  opacity: 1;
  transition: opacity 2s ease-out;
}
.image-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
}

.thumbnail {
  /* border: #666 1px solid; */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 8px;
  transition: transform 0.3s ease;
}

.thumbnail:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.lightbox-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 4;
  cursor: pointer;
  border-radius: 8px;
}

.lightbox-content {
  position: relative;
  max-width: 90%;
  max-height: 20%;
  display: flex;
  align-items: center;
}

.lightbox-image {
  max-width: 100%;
  max-height: 100%;
  border-radius: 8px;
  object-fit: contain;
  object-position: center;
}

.lightbox-nav {
  background-color: var(--primary-color);
  border: none;
  color: var(--text-color-light);
  font-size: 1rem;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  border-radius: 0.5rem;
}

.lightbox-nav.prev {
  left: 0px;
}

.lightbox-nav.next {
  right: 0px;
}

.lightbox-overlay::before {
  content: "X";
  font-size: 1.5rem;
  color: var(--error-msg-color);
  position: absolute;
  padding: 5px 5px 0 0;
  top: 0;
  right: 0;
  cursor: pointer;
  justify-content: center;
}

/* ------------ */
.container {
  width: 60vw;
  margin: 0 auto;
  padding: 0 0.5rem 0 0;
}
.header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 1rem 0;
}
.dull {
  background-color: var(--background-dull-color);
}
.sidebar-icon {
  display: none;
}

/* Container that holds the columns */
.bodyContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  padding-top: 1rem;
}

/* Left column takes up 70% width on larger screens */
.bodyLeftColumn {
  width: 69%;
  padding-right: 0.5rem;
  display: flex;
  margin-top: 0.5rem;
}
.bodyRightColumn {
  width: 30%;
}

/* column layout for admin console */
.admin-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  /* padding-top: 1rem; */
}
.admin-left-column {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: var(--primary-color);
  color: var(--text-color-light);
  width: 15%;
  margin-right: 0.1rem;
  /* margin-top: 0.5rem; */
}
.admin-right-column {
  margin-top: 1rem;
  display: flex;
  width: 83%;
}

.footer {
  background-color: var(--footer-background-color);
  color: var(--footer-text-color);
}

.footer-upper {
  display: flex;
  margin: 0.1rem auto;
  padding: 0.02rem;
  width: 60vw;
  justify-content: center;
}

.footer-lower {
  padding: 0.1rem;
  background-color: var(--footer-lower-background-color);
  text-align: center;
}
.footer-lower span {
  color: var(--secondary-color-darker);
}
.footer-lower span a {
  color: var(--secondary-color-darker);
}

.footer-column {
  width: 33%;
  margin: 0 1rem;
}

.divider-below {
  position: relative;
  display: inline-block;
  padding-bottom: 10px;
}

.divider-below::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background-color: var(--divider-color);
}
.divider-full-width {
  position: relative;
  text-align: left;
  padding-bottom: 10px;
  margin: 0.5rem 0;
}

.divider-full-width::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background-color: var(--primary-color-lighter);
  z-index: 0;
}

.submenuContainer {
  position: absolute;
  top: 100%;
  left: 0px;
  margin: 0;
  list-style-type: none;
  min-width: 200px;
  padding: 0;
  line-height: normal;
  z-index: 10;
}

.showSidebar {
  display: block;
  /* background-color: var(--primary-color); */
  height: 100vh;
  width: 60vw;
  position: absolute;
  top: 0;
  padding: 1rem;
  list-style-type: none;
}

.hideSidebar {
  display: none;
}

/* Container for the search input and button */
.search-container {
  display: flex;
  align-items: center;
}

/* Styles for the input field */
.search-input {
  height: 2rem;
  max-width: 15.625rem;
  margin-left: 0.25rem;
  border: 1px;
  padding: 0 0.5rem;
  font-size: 1rem;
}
.search-input:focus {
  outline: none;
}
/* Styles for the search button */
.search-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-color);
  border: 1px;
  height: 2rem;
  cursor: pointer;
  font-size: 1rem;
  margin-left: 0.25rem;
}

.search-button:hover {
  background-color: var(--primary-color-lighter);
}
.search-icon {
  width: 1.5rem;
  height: 1.5rem;
}

/* Accessibility styles for screen readers */
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.brand-responsive-image {
  max-width: 100px;
  height: auto;
  vertical-align: middle;
  object-fit: cover;
}
.linkRegisterNow {
  display: flex;
  justify-content: end;
  align-items: center;
  flex-wrap: wrap;
}
.brand-register-image {
  max-width: 120px;
  height: auto;
  vertical-align: middle;
  object-fit: cover;
}
.layout-full {
  padding: 1rem;
}
.navbarContainer {
  background-color: var(--primary-color);
  margin: auto;
  width: 100vw;
}
.quicklink-ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.quicklink-li {
  margin-bottom: 0.5rem;
}
.quicklink-li-link {
  color: var(--footer-text-color);
  text-decoration: none;
  padding: 0.5rem 0.75rem;
}
.quicklink-li-link::before {
  content: ">";
  margin-right: 10px;
}
.quicklink-li-link:hover {
  color: var(--primary-color-lighter);
}
.iconStyle {
  font-size: 1.5rem;
  margin-right: 0.5rem;
  content: \2709;
}
.readMoreButton {
  min-width: 120px;
  line-height: 20px;
  font-size: 1rem;
  color: var(--text-color-light);
  align-self: flex-end;
  position: relative;
  text-decoration: none;
  background-color: var(--secondary-color-darker);
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  margin: 0.5rem 0;
  border: none;
  transition: background-color 0.3s ease;
}

.readMoreButton:hover {
  background-color: var(--primary-color);
  cursor: pointer;
}
/* -------------------- */
.title-focus {
  text-align: center;
  font-weight: 600;
  font-size: 22px;
  font-family: var(--font-family-primary);
  padding: 0 0 30px;
  color: var(--secondary-color-darker);
  position: relative;
}
.title-focus:after {
  position: absolute;
  content: "";
  width: 50px;
  height: 4px;
  background: var(--secondary-color-darker);
  left: 50%;
  top: auto;
  bottom: 30%;
  transform: translate3d(-50%, 0px, 0px);
}

.section-one {
  background: var(--section-one-bg-color);
  padding: 30px 0 5px 0;
}

.section-two {
  background: var(--section-two-bg-color);
  background-image: url(../public/Images/bg-section.png);
  background-blend-mode: multiply;
  padding: 30px 0 5px;
}
.box-wrap {
  float: left;
  width: 100%;
  min-height: 100%;
  box-shadow: 0px 1px 9px var(--secondary-color-darker);
  border-radius: 10px;
  cursor: pointer;
  overflow: hidden;
  padding: 0.5rem;
}
.box-row {
  display: flex;
  padding: 1rem 1rem;
  gap: 1rem;
}
.box-head {
  float: left;
  width: 100%;
  background-color: var(--primary-color);
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px 10px 0 0;
}
.box-head span {
  font-family: var(--font-family-primary);
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
  color: var(--text-color-light);
}
.box-body {
  float: left;
  width: 100%;
  height: calc(100% - 45px);
  background: var(--text-color-light);
  padding-top: 1rem;
  border-radius: 0 0 10px 10px;
}
.page-head {
  float: left;
  width: 100%;
  background-color: var(--primary-color);
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px 10px 0 0;
  margin-top: -0.5rem;
  margin-bottom: 0.5rem;
}
.page-head span {
  font-family: var(--font-family-primary);
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
  color: var(--text-color-light);
}
.square-container {
  padding: 0 10px 0 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 5px;
}
.square-box {
  width: 150px;
  height: 150px;
  border-radius: 10px;
  background-color: var(--secondary-color);
  box-shadow: 0px 1px 9px var(--secondary-color-darker);
  padding: 5px;
  margin-bottom: 10px;
}
.square-box:hover {
  background-color: var(--primary-color-lighter);
}
.squarebox-text {
  height: 100%;
  color: var(--text-color-light);
  align-content: center;
  text-align: center;
  font-size: 1rem;
}
/*Events*/

.event-row {
  display: grid;
  grid-template-columns: 50px 1fr;
  grid-column-gap: 15px;
  align-items: flex-start;
}

.event-calend {
  background: var(--text-color-light);
  box-sizing: border-box;
}

.event-month {
  /* background: #900000; */
  background-color: var(--secondary-color);
  font-weight: bold;
  font-size: 11px;
  line-height: 24px;
  color: var(--text-color-light);
  text-align: center;
  height: 22px;
  font-family: var(--font-family-primary);
}

.event-date {
  font-weight: bold;
  font-size: 22px;
  text-align: center;
  color: var(--secondary-color-darker);
  height: 30px;
  font-family: var(--font-family-primary);
  line-height: 30px;
  border: 1px solid var(--secondary-color-darker);
}
.notice-row {
  width: 100%;
  min-height: 2.2rem;
}
/*Scrolling text similar to marquee*/

.scrolling-text {
  height: 55px;
  background: var(--secondary-color-darker);
}

.scroll-container {
  width: 100vw;
  height: 50px;
  overflow: hidden;
  position: relative;
  background-color: var(--secondary-color);
  display: flex;
  align-items: center;
}
.scroll-text {
  white-space: nowrap;
  position: absolute;
  will-change: transform;
  animation: scroll-animation 20s linear infinite;
  font-size: 16px;
  line-height: 32px;
  color: var(--text-color-light);
  width: calc(100% - 300px);
}

@keyframes scroll-animation {
  0% {
    transform: translateX(60vw);
  }
  100% {
    transform: translateX(-60vw);
  }
}

.scrolling-text {
  height: 55px;
  background: var(--secondary-color-darker);
}
/*Flip*/

.flip-card {
  background-color: transparent;
  width: 100%;
  height: 180px;
  perspective: 1000px;
  height: 100%;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  /* Safari */
  backface-visibility: hidden;
}

.flip-card-front {
  background-color: var(--text-color-light);
  color: var(--flip-card-front-font-color);
}

.flip-card-back {
  background-color: var(--secondary-color);
  color: var(--text-color-light);
  transform: rotateY(180deg);
  display: grid;
  align-content: center;
}

.flip-card-back:after {
  position: absolute;
  content: "";
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  background: transparent;
  border: solid 2px var(--flip-card-back-border-color);
  margin: 10px;
}

.flip-card-back h1 {
  font-size: 18px;
}

.flip-card-back h1,
.flip-card-back p {
  color: var(--text-color-light);
  margin: 0px;
  text-align: center;
}
figure {
  margin: 0px;
  padding: 0px;
}
.chairman-image {
  display: flex;
  width: 100px;
  margin: 0 0 0 0;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1440px) {
  .imageLogo {
    width: 50px;
    height: 50px;
  }
  .container {
    width: 90vw;
  }
  .navbar-items {
    flex-wrap: wrap;
  }
  .footer-upper {
    width: 90vw;
  }
  .socialNavbarContainer {
    width: 90vw;
  }
  .navbar {
    width: 90vw;
  }
}
@media (max-width: 1024px) {
  .square-box {
    flex: 0 0 calc(30% - 1px); /* 3 boxes per row */
    margin-bottom: 10px;
  }
}
@media (max-width: 768px) {
  .social-navbar {
    display: none;
  }
  h1 {
    font-size: 24px !important;
  }
  .mainBody {
    width: 100% !important;
  }
  .bodyContainer {
    flex-direction: column;
  }
  .bodyLeftColumn {
    width: 100%;
    margin: 0 0 0.5rem 0;
  }
  .bodyRightColumn {
    width: 100%;
    padding: 0;
  }
  .footer-upper {
    flex-direction: column;
    width: 90vw;
  }
  .footer-column {
    width: 100%;
    margin: 1rem 0;
  }
  .navbar-items {
    display: none;
  }
  .navbar {
    display: flex;
    justify-content: end;
    align-items: center;
  }
  .socialNavbarContainer {
    width: 90vw;
    justify-content: end;
  }
  .sidebar-container {
    width: 60vw;
    background-color: var(--secondary-color-darker);
    color: var(--text-color-light);
    height: 100%;
    padding: 0.5rem;
    overflow: hidden;
    transition: width 0.3s ease-in-out, padding 0.3s ease-in-out;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 10;
  }
  .sidebar-ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  .sidebar-link {
    color: var(--text-color-light);
    text-decoration: none;
    display: block;
    padding: 0.5rem 0.75rem;
    background-color: var(--secondary-color);
  }
  .sidebar-submenu-ul {
    list-style-type: none;
    padding: 0.3125rem 0 0 1.25rem;
    margin: 0;
  }
  .sidebar-submenu-link {
    color: var(--text-color-light);
    text-decoration: none;
    display: block;
    padding: 0.375rem 0.625rem;
    background-color: var(--primary-color);
    border-radius: 0.1875rem;
  }

  .sidebar-icon {
    display: block;
    font-size: large;
    height: 100%;
    padding: 0 1rem;
    background-color: var(--secondary-color);
    color: var(--text-color-light);
    border: 0;
  }

  .box-row {
    flex-direction: column;
  }
  .square-box {
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 600px) {
  .pdf-table {
    border: 0;
  }

  .pdf-table thead {
    display: none;
  }

  .pdf-table tr {
    display: block;
    margin-bottom: 10px;
  }

  .pdf-table td {
    display: block;
    font-size: 14px;
    text-align: right;
    position: relative;
    padding-left: 50%;
    padding-right: 10px;
    border: none;
  }

  .pdf-table td::before {
    content: attr(data-label);
    position: absolute;
    left: 0;
    width: 50%;
    padding-left: 10px;
    white-space: nowrap;
    font-weight: bold;
  }
}

@media (max-width: 480px) {
  .container {
    flex-direction: column;
    width: 90vw;
  }
  .bodyContainer {
    flex-direction: column;
    padding-right: 0.5rem;
  }
  .bodyLeftColumn {
    width: 100%;
  }
  .bodyRightColumn {
    width: 100%;
    padding: 0;
    margin: 0 0.5rem 0.5rem 0;
  }

  .box-row {
    flex-direction: column;
  }
  .square-box {
    height: 90px;
    width: 90px;
  }
  .squarebox-text {
    font-size: 0.8rem;
  }

  .image-grid-container {
    min-height: 250px;
  }
  .thumbnail {
    width: 100px;
    height: 100px;
  }
}
@media (max-width: 375px) {
  .thumbnail {
    width: 75px;
    height: 75px;
  }
}

/* login form */
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* min-height: 100vh; */
  background-color: var(--background-color);
  width: 100%;
}

.login-form {
  background-color: var(--background-color);
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
}

.login-form h2 {
  text-align: center;
  margin-bottom: 1.5rem;
  color: var(--text-color);
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  color: var(--text-color);
}

.form-group input {
  width: 100%;
  padding: 0.8rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.form-group input:focus {
  border-color: var(--primary-color);
  outline: none;
  background-color: var(--background-color);
}

.adminlogin-button {
  background-color: var(--secondary-color);
  color: var(--text-color-light);
  border: none;
  padding: 0;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  display: inline-block;
  width: 40%;
}
.adminlogin-link {
  color: var(--text-color-light);
  text-decoration: none;
  display: block;
  padding: 10px 20px;
  text-align: center;
}

.adminlogin-button:hover {
  background-color: var(--primary-color);
}

.error-message {
  color: var(--error-msg-color);
  text-align: center;
  margin-bottom: 1rem;
}
.hero-image {
  width: 100vw;
  height: 8rem;
  margin-bottom: -0.5rem;
}

/* input type file */
.input-type-file {
  font-size: 1.5rem;
  line-height: 2rem;
  border: 1px solid var(--secondary-color-darker);
  margin-right: 5px;
  color: var(--text-color-light);
  background-color: var(--primary-color);
}
.input-type-file:hover {
  background-color: var(--secondary-color);
}
.submit-button {
  font-size: 1.5rem;
  color: var(--text-color-light);
  border-width: 0;
  background-color: var(--primary-color);
}
.submit-button:hover {
  background-color: var(--secondary-color);
  cursor: pointer;
}

/* Container for the file upload form */
.page-head-container {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logout-button {
  position: absolute;
  right: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
  background-color: var(--error-msg-color);
  border: none;
  border-radius: 5px 5px 5px 5px;
  width: 80px;
}
.rightside-image {
  position: absolute;
  right: 0.5rem;
  top: 51%;
  transform: translateY(-50%);
  /* background-color: var(--error-msg-color); */
  border: none;
  /* border-radius: 5px 5px 5px 5px; */
  /* width: 80px; */
}
.logout-button .component-logout-button {
  background-color: #f44336;
  color: var(--flip-card-back-border-color);
  font-family: var(--font-family-primary);
  border: none;
  padding: 0.1rem 0.5rem;
  font-size: 1rem;
  border-radius: 5px 5px 5px 5px;
  width: 100%;
  cursor: pointer;
}
.component-logout-button {
  border-width: 0;
}
.component-logout-button:hover {
  background-color: #c51c10;
}
.upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  /* text-align: center; */
}
.upload-container img {
  width: 100px;
  margin: auto;
  max-width: 400px;
}

.uploadfile-control {
  width: 90%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3rem;
}

.uploadfile-control input {
  width: 70%;
  border: 1.5px solid var(--primary-color);
  color: var(--text-color-light);
  background-color: #a8a8a8;
  padding-left: 0.5rem;
  border-radius: 0.2rem;
}

.uploadfile-control input :hover {
  background-color: var(--secondary-color);
}
.uploadfile-control button {
  width: 120px;
  padding: 0.225rem;
  margin-left: 2px;
  color: var(--text-color-light);
  border: 1px solid var(--primary-color);
  background-color: var(--secondary-color);
  border-radius: 0.2rem;
}
.uploadfile-control button:hover {
  background-color: var(--primary-color);
}
/* Feedback message styles */
.upload-container p {
  margin-top: 10px;
  font-size: 16px;
}

.upload-container p.error {
  color: red;
}

.upload-container p.success {
  color: green;
}
/* Feedback message styles */
.upload-container p {
  margin-top: 10px;
  font-size: 16px;
  opacity: 1;
  transition: opacity 2s ease-out;
}

/* Hidden state after fade-out */
.hidden {
  opacity: 0;
  visibility: hidden;
  transition: visibility 0s 5s;
}

.upload-container p.error {
  color: red;
}

.upload-container p.success {
  color: green;
}

/* Styles for UploadedGallery */
.gallery-container {
  width: auto;
  padding: 20px;
  margin-right: 20px;
}
.gallery-container p {
  margin-top: 10px;
  font-size: 16px;
  opacity: 1;
  transition: opacity 2s ease-out;
}
.gallery-container p.error {
  color: red;
}

.gallery-container p.success {
  color: green;
}

.gallery-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 10px;
}

.gallery-item {
  position: relative;
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
}

.gallery-item img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.gallery-item:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.delete-button {
  position: absolute;
  top: calc(50%-12px);
  right: 0px;
  background: var(--primary-color, 0.8);
  /* rgba(255, 255, 255, 0.8);  */
  color: var(--text-color-light);
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.delete-button:hover {
  background: rgba(255, 0, 0, 0.8);
}
/* Admin navbar css */
.adminnavbar-container {
  position: relative;
  width: 100%;
  background-color: var(--secondary-color);
  color: var(--text-color-light);
  padding: 0.5rem;
  overflow: hidden;
  transition: width 0.3s ease-in-out, padding 0.3s ease-in-out;
  left: 0;
  top: 0;
}
.adminnavbar-container ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.adminnavbar-container-li {
  padding: 0.1rem;
  font-size: 1rem;
  margin: 0;
}
.adminnavbar-container-li :active ::selection {
  color: red;
}
.adminnavbar-link {
  color: var(--text-color-light);
  text-decoration: none;
  display: block;
  padding: 0.5rem 0.5rem;
  background-color: var(--secondary-color-darker);
}
.adminnavbar-link:hover {
  cursor: pointer;
  background-color: var(--primary-color);
}

.adminvar-submenu-ul {
  list-style-type: none;
  padding: 0.3125rem 0 0 1.25rem;
  margin: 0;
}
.adminvar-submenu-li {
  list-style-type: none;
  padding: 0.3125rem 0 0 1.25rem;
  margin-left: 10px;
  margin: 0;
}
.adminbar-submenu-link {
  color: var(--text-color-light);
  text-decoration: none;
  display: block;
  padding: 0.375rem 0.625rem;
  background-color: var(--primary-color);
  border-radius: 0.1875rem;
}
.adminbar-submenu-link:hover {
  background-color: var(--secondary-color-darker);
}
.adminbar-logout-container {
  margin-top: 0.2rem;
  text-align: center;
  align-content: center;
}

.component-logout-button {
  padding: 0.5rem;
  background-color: var(--secondary-color-darker);
  color: var(--secondary-color-alt);
  font-family: var(--font-family-primary);
  position: relative;
  width: 100%;
  font-size: 1rem;
}
.component-logout-button:hover {
  background-color: var(--primary-color);
  font-size: 1.1rem;
}
.admin-panel {
  background-color: var(--secondary-color);
  margin-bottom: 2rem;
  text-align: center;
}
/* pdf table css */
.pdf-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px, auto;
}

.pdf-table th,
.pdf-table td {
  border: 1px solid var(--secondary-color-alt);
  padding: 8px;
  text-align: left;
}

.pdf-table th {
  background-color: var(--secondary-color-alt);
  text-align: center;
}

.pdf-table a {
  color: var(--primary-color);
  text-decoration: none;
}

.pdf-table button {
  background-color: var(--secondary-color-darker);
  color: var(--text-color-light);
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}

.pdf-table button:hover {
  background-color: var(--primary-color);
}
