.brand-content-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  flex-wrap: nowrap;
}

.brand-responsive-image {
  max-width: 100px;
  height: auto;
  vertical-align: middle;
  object-fit: cover;
}

.brand-text-container {
  flex: 2 1 1rem;
}
@media (max-width: 480px) {
  .brand-content-container {
    flex-direction: column;
    align-items: center;
    text-align: center;
    flex-wrap: wrap;
  }
  .brand-responsive-image {
    display: block;
    margin: 0 auto;
  }
}
@media (max-width: 768px) {
  .brand-content-container {
    width: 100%;
  }
  .brand-text-container {
    width: 100%;
  }
}
@media (max-width: 1440) {
  .brand-content-container {
    flex-grow: 1;
    width: 100%;
  }
  .brand-text-container {
    width: 100%;
  }
}
